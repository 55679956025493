import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import Router from './Router';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyABULfx5JO8-8RIc7IvKWuoFdTLJ2gA1dU",
  authDomain: "beatstrings-2ef9e.firebaseapp.com",
  projectId: "beatstrings-2ef9e",
  storageBucket: "beatstrings-2ef9e.appspot.com",
  messagingSenderId: "972043407090",
  appId: "1:972043407090:web:1918dae9f3b3a977428a6b",
  measurementId: "G-73E23GKCQB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root')
);
